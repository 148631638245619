import './Work.css'
import Carousel from 'react-bootstrap/Carousel'

import calculatorImg from '../../files/calculator.png'
import weatherAppImg from '../../files/weather-app_img.png'
import porfolioImg from '../../files/portfolio_img.png'
import insuranceImg from '../../files/insurance.png'
import translatorImg from '../../files/translator.png'

import Button from '../UI/Button'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'

import gsap from 'gsap'
import { useGSAP } from '@gsap/react'

const Work = function () {
	document.title = 'WebDev | Work'
	useGSAP(() => {
		gsap.to('.work-carousel', { duration: 0.7, x: 0, opacity: 1, ease: 'power2.out' })
	})

	return (
		<Carousel
			wrap={true}
			interval={null}
			nextIcon={<ArrowCircleRightIcon className="arrow-icon" style={{ fontSize: '50px' }} />}
			prevIcon={<ArrowCircleLeftIcon className="arrow-icon" style={{ fontSize: '50px' }} />}
		>
			<Carousel.Item>
				<div className="work-carousel">
					<div className="work-carousel__content">
						<h3 className="work-carousel__title">Translator</h3>
						<p className="work-carousel__text">
							Responsive translator using DeepL API. Implemented server-side logic with Express.js and created dynamic UI components with
							Material UI.{' '}
						</p>
						<p className="work-carousel__stack">
							<b>Technologies:</b> TypeScript, React, Node.js, Express.js, Material UI, DeepL API.
						</p>
					</div>
					<div className="work-carousel__image">
						<img src={translatorImg} alt="Fifth slide" />
					</div>
					<div className="work-carousel__buttons">
						<Button text="Discover" path="https://yaroslavudych.github.io/translator" target="_blank" rel="noreferrer" />
						<Button text="View code" path="https://github.com/YaroslavUdych/translator" target="_blank" rel="noreferrer" />
					</div>
				</div>
			</Carousel.Item>
			<Carousel.Item>
				<div className="work-carousel">
					<div className="work-carousel__content">
						<h3 className="work-carousel__title">Insurance Management Application</h3>
						<p className="work-carousel__text">
							Fullstack RESTful project for managing insurance data. Implemented roles (admin and employee) with secure login system.
						</p>
						<p className="work-carousel__stack">
							<b>Technologies:</b> MongoDB, Express.js, React, Node.js, Jest, Material UI.
						</p>
					</div>
					<div className="work-carousel__image">
						<img src={insuranceImg} alt="Fourth slide" />
					</div>
					<div className="work-carousel__buttons">
						<Button text="Discover" path="https://insurance-app.fun" target="_blank" rel="noreferrer" />
						<Button text="View code" path="https://github.com/YaroslavUdych/Insurance-application" target="_blank" rel="noreferrer" />
					</div>
				</div>
			</Carousel.Item>
			<Carousel.Item>
				<div className="work-carousel">
					<div className="work-carousel__content">
						<h3 className="work-carousel__title">Portfolio</h3>
						<p className="work-carousel__text">
							Personal portfolio website featuring animations and a contact form. Styled with Material UI and CSS, animations done with GSAP.
						</p>
						<p className="work-carousel__stack">
							<b>Technologies:</b> React, Node.js, Express.js, Material UI, CSS, GSAP.
						</p>
					</div>
					<div className="work-carousel__image">
						<img src={porfolioImg} alt="Third slide" />
					</div>
					<div className="work-carousel__buttons">
						<Button text="Discover" path="https://webdevelop.cz" target="_blank" rel="noreferrer" />
						<Button text="View code" path="https://github.com/YaroslavUdych/portfolio" target="_blank" rel="noreferrer" />
					</div>
				</div>
			</Carousel.Item>
			<Carousel.Item>
				<div className="work-carousel">
					<div className="work-carousel__content">
						<h3 className="work-carousel__title">Weather app</h3>
						<p className="work-carousel__text">Application displaying weather data by geolocation with city autofill for Czech Republic.</p>
						<p className="work-carousel__stack">
							<b>Technologies:</b> JavaScript, CSS, HTML, OpenWeather API.
						</p>
					</div>
					<div className="work-carousel__image">
						<img src={weatherAppImg} alt="Second slide" />
					</div>
					<div className="work-carousel__buttons">
						<Button text="Discover" path="https://yaroslavudych.github.io/weather/" target="_blank" rel="noreferrer" />
						<Button text="View code" path="https://github.com/YaroslavUdych/weather" target="_blank" rel="noreferrer" />
					</div>
				</div>
			</Carousel.Item>
			<Carousel.Item>
				<div className="work-carousel">
					<div className="work-carousel__content">
						<h3 className="work-carousel__title">Calculator</h3>
						<p className="work-carousel__text">Mobile-friendly calculator application</p>
						<p className="work-carousel__stack">
							<b>Technologies:</b> JavaScript, CSS, HTML
						</p>
					</div>
					<div className="work-carousel__image">
						<img src={calculatorImg} alt="First slide" />
					</div>
					<div className="work-carousel__buttons">
						<Button text="Discover" path="https://yaroslavudych.github.io/calculator/" target="_blank" rel="noreferrer" />
						<Button text="View code" path="https://github.com/YaroslavUdych/calculator" target="_blank" rel="noreferrer" />
					</div>
				</div>
			</Carousel.Item>
		</Carousel>
	)
}

export default Work
